<template>
	<div>
		<BaseLoading v-if="editInfo.isLoading" />
		<FormPostcodeAdditionalFee
			v-else
			:fee="editInfo.data.fee"
			:post-code-list="selectedPostcodeList"
			:is-submitting="editInfo.isUpdating"
			@onSubmit="handleSubmit"
			@onRemoveRule="handleRemoveRule"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormPostcodeAdditionalFee from '@/components/FormPostcodeAdditionalFee.vue';
import { priceToAPI } from '../assets/js/helpers';
import { SHIPPING_ADDITIONAL_FEE_TYPE } from '../enums/shippings';

export default {
	name: 'ShippingPostcodeAdditionalFeeEdit',

	components: {
		FormPostcodeAdditionalFee,
	},
	data() {
		return {
			feeId: this.$route.params.feeId,
		};
	},
	computed: {
		...mapState('additionalFees', {
			editInfo: (state) => state.additionalFees[SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE].edit,
		}),
		selectedPostcodeList() {
			return this.editInfo && this.editInfo.data && this.editInfo.data.items && this.editInfo.data.items.map((item) => `${item.id}`);
		},
	},
	async mounted() {
		await this.getAdditionalFee({
			id: this.feeId,
			type: SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE,
		});
	},
	methods: {
		...mapActions({
			getAdditionalFee: 'additionalFees/getAdditionalFee',
			updateAdditionalFee: 'additionalFees/updateAdditionalFee',
			deleteAdditionalFee: 'additionalFees/deleteAdditionalFee',
		}),

		async handleSubmit(fee, postcodeList) {
			const params = {
				fee: priceToAPI(fee),
				type: SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE,
				item_ids: postcodeList,
			};

			await this.updateAdditionalFee({
				type: SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE,
				id: this.feeId,
				params,
			});
		},

		async handleRemoveRule() {
			// TODO: Wait for integrate API
			const feeId = this.$route.params.feeId;
			await this.deleteAdditionalFee(feeId);
			this.$router.push({ name: 'ShippingSetting' });
		},
	},
};
</script>
